/* Hero section with gradient */
.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh; /* Ensure section takes up enough height */
  color: #fff;
  background: linear-gradient(135deg, #1c0d27, #472766);
  padding: 20px;
  margin: 10px 15px;
  border-radius: 12px;
  text-align: center;
  opacity: 0;
  animation: fadeIn 1.5s ease forwards; /* Simpler fade-in animation */
}

/* Content inside hero section */
.hero-content {
  padding: 10px;
  max-width: 100%;
}

/* Headings */
.hero-content h1 {
  font-size: 2.2rem;
  margin-bottom: 15px;
}

.hero-content h2 {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

/* Accent color */
.vibrant {
  color: #BA375B;
}

/* Image */
.startup img {
  width: 100%;
  max-width: 400px; /* Ensure image is responsive */
  margin-top: 15px;
  border-radius: 8px;
}

/* Responsive adjustments for tablets and small screens */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 1.8rem;
  }

  .hero-content h2 {
    font-size: 1.1rem;
  }

  .startup img {
    max-width: 300px; /* Adjust image for smaller screens */
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 10px;
    margin: 10px;
  }

  .hero-content h1 {
    font-size: 1.5rem;
  }

  .hero-content h2 {
    font-size: 1rem;
  }

  .startup img {
    max-width: 250px;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px); /* Small vertical slide-in */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
