/* AccountInfo.css */


.account-info {
  flex: 1;
  padding: 20px;
}

.account-info h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: center;
}

.profile-photo-section, .profile-info-section, .contact-details-section {
  margin-bottom: 60px;
}

.profile-photo-section h3, .profile-info-section h3, .contact-details-section h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-left: 30px;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
}

.profile-photo-section .profile-photo {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto 20px;
}

.profile-photo-section .profile-photo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-photo-section .upload-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.profile-info-section form, .contact-details-section form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-info-section form .form-group, .contact-details-section form .form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin-bottom: 15px;
}

.profile-info-section form label, .contact-details-section form label {
  margin-bottom: 5px;
  font-size: 1rem;
}

.profile-info-section form input, .contact-details-section form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 15px;
}

.profile-info-section form select, .contact-details-section form select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 15px;
  border-right: 16px solid transparent;
  background-color: transparent;
}

input[disabled], select[disabled] {
  background-color: #fff;
  cursor: not-allowed;
}

.contact-details-section p {
  margin-bottom: 20px;
  padding: 0px 20px;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
}

.save-profile-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.save-profile-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #444;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-profile-button:hover {
  background-color: #222;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
