/* General container styling */
.signup-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  font-family: 'Arial', sans-serif;
  transition: all 0.5s ease;
}

/* Left side styling (Background and text) */
.signup-left {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1c0d27, #BA375B, #472766);
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  position: relative;
  text-align: center;
  color: 472766;
  z-index: 1;
}

.overlay h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.overlay p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.signin-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
}

.signin-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Right side styling (Form) */
.signup-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
}

.signup-right h2 {
  font-size: 2.5em;
  text-align: center;
}

h2 .highlight {
  color: #472766;
}

.signup-right h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
}

.signup-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-form label {
  align-self: flex-start;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #555;
}

.signup-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.signup-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #472766;
  color: white;
  cursor: pointer;
  font-size: 1em;
}

.signup-button:hover {
  background-color: #8e44ad;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .signup-left {
      display: none; /* Hide the left side on screens smaller than 768px */
  }

  .signup-right {
      width: 100%;
      padding: 20px;
  }

  .signup-right h2 {
      font-size: 2em;
  }

  .signup-right h3 {
      font-size: 1.3em;
  }

  .signup-form input {
      font-size: 0.95em;
      padding: 8px;
  }

  .signup-button {
      font-size: 0.95em;
      padding: 8px;
  }
}

@media (max-width: 480px) {
  .signup-right h2 {
      font-size: 1.8em;
  }

  .signup-form input {
      font-size: 0.9em;
      padding: 8px;
  }

  .signup-button {
      padding: 8px;
      font-size: 0.9em;
  }
}
