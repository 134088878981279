/* Base Styles */
.create-event-banner {
  background: linear-gradient(135deg, #1c0d27, #472766);
  color: #fff;
  padding: 50px 20px;
  text-align: center;
  margin-bottom: 40px;
}

.create-event-banner .content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.create-event-banner h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #BA375B;
}

.create-event-banner p {
  margin-bottom: 25px;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ddd;
}

.create-event-button {
  background-color: #BA375B;
  color: #000;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.create-event-button:hover {
  background-color: #ca385f;
}

/* Responsive Design */

/* Tablets (max-width: 768px) */
@media (max-width: 768px) {
  .create-event-banner h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .create-event-banner p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .create-event-button {
    padding: 12px 25px;
    font-size: 1.1rem;
  }
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .create-event-banner {
    padding: 40px 15px;
  }

  .create-event-banner h2 {
    font-size: 1.75rem;
    margin-bottom: 12px;
  }

  .create-event-banner p {
    font-size: 0.95rem;
    margin-bottom: 15px;
  }

  .create-event-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}
