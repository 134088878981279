/* Sidebar styling */
.account-settings-sidebar {
  flex: 0 0 250px;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.account-settings-sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.account-settings-sidebar ul {
  list-style: none;
  padding: 0;
}

.account-settings-sidebar ul li {
  font-size: 1rem;
  padding: 10px 0;
  cursor: pointer;
  transition: color 0.3s ease;
}

.account-settings-sidebar ul li.active,
.account-settings-sidebar ul li:hover {
  color: #007bff;
}

/* Responsive styling */
@media (max-width: 768px) {
  .account-settings-sidebar {
    flex: 0 0 auto;
    padding: 10px;
    border-right: none;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }

  .account-settings-sidebar h2 {
    font-size: 1.2rem;
  }

  .account-settings-sidebar ul li {
    font-size: 0.9rem;
    padding: 8px 0;
  }
}

@media (max-width: 480px) {
  .account-settings-sidebar h2 {
    font-size: 1rem;
  }

  .account-settings-sidebar ul li {
    font-size: 0.85rem;
  }
}