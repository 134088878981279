/* Base Styles */
.subscribe-section {
  background-color: #BA375B;
  padding: 40px 0;
  text-align: center;
}

.subscribe-section .content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px; /* Add some padding for smaller screens */
}

.subscribe-section h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #262626;
}

.subscribe-section p {
  margin-bottom: 25px;
  color: #333;
  font-size: 1.1rem;
}

.subscribe-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-form input {
  padding: 15px;
  border: none;
  border-radius: 10px 0 0 10px;
  width: 350px;
  outline: none;
  font-size: 1rem;
}

.subscribe-form button {
  padding: 15px 25px;
  border: none;
  background-color: #472766;
  color: #fff;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.subscribe-form button:hover {
  background-color: #472766;
}

/* Responsive Design */

/* Medium Screens (Tablets) */
@media (max-width: 768px) {
  .subscribe-section h2 {
    font-size: 1.75rem;
  }

  .subscribe-section p {
    font-size: 1rem;
  }

  .subscribe-form input {
    width: 300px;
    padding: 14px;
  }

  .subscribe-form button {
    padding: 14px 20px;
  }
}

/* Small Screens (Mobile) */
@media (max-width: 480px) {
  .subscribe-section h2 {
    font-size: 1.5rem;
  }

  .subscribe-section p {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }

  .subscribe-form {
    flex-direction: row;
  }

  .subscribe-form input {
    width: 300px;
    padding: 14px;
  }

  .subscribe-form button {
    padding: 14px 20px;
  }
}
