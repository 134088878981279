/* Footer with gradient background */
.footer {
  background: linear-gradient(135deg, #1c0d27, #472766); /* Dark to light purple gradient */
  color: #fff;
  padding: 40px 20px;
  text-align: start;
  /* border-top-left-radius: 12px; */
  /* border-top-right-radius: 12px; */
}

/* Footer Content Layout */
.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-section h4 {
  font-size: 1.4rem;
  margin-bottom: 15px;
  color: #ffffff;
}

.footer-content h4 {
  margin-bottom: 15px;
  color: #ffffff;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  color: #d3d3d3; /* Slightly lighter for contrast */
}

.footer-section ul li {
  margin-bottom: 14px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.footer-section ul li a {
  text-decoration: none;
  color: white;
}

.footer-section ul li:hover {
  color: #BA375B; /* Highlight color on hover */
}

.footer-section ul li a:hover {
  color: #BA375B; /* Highlight color on hover */
}


.app-logos {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.app-logos img {
  max-width: 40px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.app-logos img:hover {
  transform: scale(1.1);
}

.footer-bottom {
  margin-top: 20px;
  border-top: 1px solid #574a66; /* Lighter border to fit gradient theme */
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #d3d3d3; /* Lighter text for legibility */
}

/* Responsive Design */

/* Tablets and medium-sized screens */
@media (max-width: 768px) {
  .footer-section {
    min-width: 150px;
    flex: 1 0 45%;
    margin-bottom: 30px;
  }

  .footer-content {
    justify-content: space-evenly;
    padding: 0 10px;
  }

  .app-logos img {
    max-width: 35px;
  }

  .footer-section h4 {
    font-size: 1.2rem;
  }
}

/* Mobile Screens */
@media (max-width: 480px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
  }

  .footer-section {
    flex: 1 0 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-section ul {
    padding: 0;
  }

  .footer-section ul li {
    margin-bottom: 10px;
  }

  .footer-section ul li a {
    text-decoration: none;
    color: white;
  }

  .footer-section h4 {
    font-size: 1.1rem;
    margin-bottom: 12px;
  }

  .app-logos img {
    max-width: 30px;
    margin: 5px;
  }

  .footer-bottom {
    font-size: 12px;
    padding-bottom: 10px;
  }
}
