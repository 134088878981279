.privacy-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-header {
    text-align: start;
    margin-bottom: 20px;
    margin-top: 100px;
  }
  
  .privacy-header h1 {
    margin: 0;
    font-size: 2rem;
  }
  
  .privacy-header p {
    margin: 5px 0;
    color: #555;
  }
  
  .toc {
    margin-bottom: 20px;
  }
  
  .toc h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .toc ul {
    list-style: none;
    padding: 0;
  }
  
  .toc li {
    margin: 5px 0;
  }
  
  .toc button {
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .toc button:hover {
    text-decoration: none;
  }
  
  .content {
    margin-bottom: 30px;
  }
  
  .content section {
    margin-bottom: 20px;
  }
  
  .content h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .content p {
    margin: 0 0 10px;
  }
  
  .footer {
    text-align: center;
    margin-top: 30px;
    font-size: 0.9rem;
    color: #666;
  }
  