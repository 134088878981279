/* Upcoming Events Section with Animations */
.upcoming-events-section {
  padding: 40px 60px;
  text-align: center;
  opacity: 0;
  transform: translateY(20px); /* Initial slide effect */
  animation: fadeInSlideUp 1.5s ease forwards; /* Fade and slide-up animation */
}

.events-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  padding: 10px 0px;
}

/* Section Title */
.upcoming-events-section h2 {
  font-size: 2.5em;
  color: #212a4e;
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeInTitle 1.5s ease forwards;
  animation-delay: 0.3s; /* Delay for staggered animation */
}

/* Animation for Section */
@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Event Cards */
.events-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
  padding: 0px 0px;
  opacity: 0;
  animation: fadeInGrid 1.5s ease forwards;
  animation-delay: 0.5s;
}

/* Fade in grid of event cards */
@keyframes fadeInGrid {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Event Card Animation on Load */
.event-card {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInCard 1s ease forwards;
}

/* Staggered animation for event cards */
.event-card:nth-child(1) {
  animation-delay: 0.3s;
}
.event-card:nth-child(2) {
  animation-delay: 0.4s;
}
.event-card:nth-child(3) {
  animation-delay: 0.5s;
}

/* Load More Button */
.load-more {
  background: #4b2ccf;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
  opacity: 0;
  animation: fadeInButton 1.5s ease forwards;
  animation-delay: 0.8s;
}

/* Hover Effect for Load More Button */
.load-more:hover {
  background: #3a22a6;
  transform: scale(1.05);
}

/* Loader */
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #212a4e;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

/* Spin Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error Message Styling */
.error-message {
  color: red;
  text-align: center;
  margin: 20px;
}

.error-message button {
  background-color: #f0c040;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.error-message button:hover {
  background-color: #d89a1c;
}

/* Fade-in animations for each element */
@keyframes fadeInTitle {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInCard {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInButton {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
