/* Sleek floating header */
.header {
  position: fixed;
  width: 90%; /* Reduced width for a floating look */
  top: 20px; /* Space from the top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: linear-gradient(135deg, #1c0d27, #472766); /* Dark to lighter purple gradient */
  padding: 10px 20px; /* Padding to add sleekness */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Apply slight background change on scroll */
.header.scrolled {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Container inside the header */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Smaller logo */
.logo {
  height: 40px; /* Reduced size for sleekness */
  margin-right: 10px;
}

/* Mobile menu toggle button */
.mobile-menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* Navigation styling */
.nav ul {
  list-style: none;
  display: flex;
  transition: all 0.3s ease;
}

.nav ul li {
  margin: 0 10px;
}

.nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
}

/* Responsive mobile menu */
@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
  }

  .nav {
    display: none;
  }

  .nav.open {
    display: block;
    width: 100%;
    background: linear-gradient(135deg, #1c0d27, #472766);
    position: absolute;
    top: 60px;
    left: 0;
    padding: 20px;
    border-radius: 12px;
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
  }

  .nav ul li {
    margin: 10px 0;
  }
}
