/* EventCard.css */

.event-card {
    background: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 310px;
    margin: 10px;
    text-decoration: none;
  }
  
  .event-card-image {
    width: 100%;
    object-fit: cover;
  }
  
  .event-card-details {
    padding: 15px;
    position: relative;
    text-align: start;
  }
  
  .event-card-date {
    position: absolute;
    top: -30px;
    left: 15px;
    background: white;
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .event-card-month {
    display: block;
    font-size: 14px;
    color: #888;
  }
  
  .event-card-day {
    display: block;
    font-size: 24px;
    font-weight: bold;
    color: #212a4e;
  }
  
  .event-card-info {
    margin-top: 40px;
  }
  
  .event-card-title {
    font-size: 1.1em;
    margin-bottom: 20px;
    color: #212a4e;
  }
  
  .event-card-description {
    color: #777;
    font-size: 0.9em;
  }
  
  .event-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .event-card {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .event-card {
      width: 100%;
    }
  }
  