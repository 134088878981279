.account-settings-container {
  display: flex;
  margin: 20px auto;
  max-width: 1200px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

/* Responsive styling */
@media (max-width: 768px) {
  .account-settings-container {
    flex-direction: column;
    padding: 10px;
  }
}


@media (max-width: 480px) {
  .account-settings-container {
    margin: 10px auto;
    padding: 10px;
  }
}