/* src/pages/NotFoundPage.css */
.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .not-found-content {
    max-width: 600px;
  }
  
  .not-found-404 {
    animation: float 3s infinite ease-in-out;
  }
  
  .not-found-404 img {
    width: 100%;
    max-width: 300px;
  }
  
  .not-found-h1 {
    font-size: 3em;
    color: #333;
  }
  
  p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .back-home-button {
    background-color: #262626;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1em;
  }
  
  .back-home-button:hover {
    background-color: #6c47e8;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
  }
  
  .social-links a img {
    width: 40px;
    height: 40px;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  